import { CLink } from '@/CLink';
import { Dialog, DialogClose, DialogContent, DialogOpen } from '@/DialogPrimitive';
import { PlainBtn } from '@/buttons';
import { NavLinksTypes, navLinks, siteInfo } from '@/layout/NavLinks';
import { ReactComponent as CloseIcon } from '@a/icons/close.svg';
import { ReactComponent as MenuIcon } from '@a/icons/menu.svg';
import { ReactComponent as ArrowIcon } from '@a/icons/mini-arrow.svg';
import { ReactComponent as PhoneIcon } from '@a/icons/phone.svg';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import useLocationData from '@s/hooks/useLocationData';
import useRouteChange from '@s/hooks/useRouteChange';
import { navigate } from 'gatsby';
import { useState } from 'react';
import { CallBtn, HomeLogo } from './Nav';
import { navBtnStyles, navLinkStyles } from './NavLinkStyles';
import { theme } from './styles/GlobalStyles';
import { flexSpace, flexStart, px } from './styles/classes';

const overlayStyles = css`
    display: none;
`;

const contentStyles = css`
    ${flexStart};
    ${px};
    flex-direction: column;
    align-items: center;
    background-image: ${theme.colors.gradient};
    padding-top: 16px;
    padding-bottom: 16px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &[data-state='open'] {
        animation: slideIn 300ms ease-in;
    }

    &[data-state='closed'] {
        animation: slideOut 300ms ease-out;
    }

    @keyframes slideIn {
        from {
            transform: translateX(100vw);
        }
        to {
            transform: translateX(0);
        }
    }

    @keyframes slideOut {
        from {
            transform: translateX(0);
        }
        to {
            transform: translateX(100vw);
        }
    }

    @media (min-width: 1280px) {
        display: none;
    }
`;

const Top = styled.div`
    ${flexSpace};
    width: 100%;
    padding-bottom: 16px;

    > a:nth-of-type(2) {
        margin-left: auto;
    }
`;

const Nav = styled.nav<{ subNav: boolean }>`
    ${flexStart};
    align-items: ${({ subNav }) => (subNav ? 'flex-start' : 'center')};
    flex-direction: column;
    height: calc(100% - 80px);
    max-width: max-content;
    margin: 0 auto;
    overflow-y: auto;

    ${({ subNav }) =>
        subNav &&
        css`
            > a {
                width: 100%;
            }
        `};
`;

export const MobNav = ({ text, links = navLinks }: { text?: string; links?: NavLinksTypes }) => {
    const [navOpen, setNavOpen] = useState(false);

    const scroll = (link: string) => {
        if (link === '#appointment') {
            setNavOpen(false);
            setTimeout(() => navigate(link), 250);
        }
    };

    useRouteChange(setNavOpen);

    const { category } = useLocationData();
    return (
        <Dialog open={navOpen} onOpenChange={setNavOpen}>
            <DialogOpen>
                {text ? (
                    <PlainBtn
                        className={category === text ? 'current-nav-category' : ''}
                        css={css`
                            ${navBtnStyles};
                            margin-bottom: min(3.92vh, 32px);
                        `}
                    >
                        {text} <ArrowIcon />
                    </PlainBtn>
                ) : (
                    <PlainBtn
                        aria-label="open nav menu"
                        css={css`
                            > svg > path {
                                transition: fill 0.3s ease-in-out;
                            }

                            @media (min-width: 1280px) {
                                display: none;
                            }
                        `}
                    >
                        <MenuIcon />
                    </PlainBtn>
                )}
            </DialogOpen>
            <DialogContent
                contentStyles={contentStyles}
                overlayStyles={overlayStyles}
                title="mobile nav menu"
            >
                <Top>
                    <HomeLogo />

                    <CallBtn
                        as={CLink}
                        to={siteInfo.phone.link}
                        aria-label={`call us at ${siteInfo.phone.text}`}
                    >
                        <PhoneIcon />
                    </CallBtn>

                    <DialogClose>
                        <PlainBtn aria-label="close nav menu">
                            <CloseIcon />
                        </PlainBtn>
                    </DialogClose>
                </Top>

                <Nav subNav={!!text}>
                    {links.map((data, i) =>
                        data.links ? (
                            <MobNav text={data.text} links={data.links} key={i} />
                        ) : data.text === 'contact us' ? (
                            <CLink
                                to={data.link}
                                css={theme => css`
                                    ${navLinkStyles};
                                    color: ${theme.colors.light};
                                    margin-top: auto;
                                    margin-bottom: min(3.92vh, 32px);

                                    > svg path {
                                        fill: ${theme.colors.light};
                                    }

                                    ::before {
                                        border-image-source: linear-gradient(
                                            0deg,
                                            ${theme.colors.light},
                                            ${theme.colors.light}
                                        );
                                        opacity: 1;
                                    }
                                `}
                                key={i}
                            >
                                {data.text} <ArrowIcon />
                            </CLink>
                        ) : (
                            <CLink
                                to={data.link}
                                css={css`
                                    ${navLinkStyles};
                                    max-width: 340px;
                                    margin-bottom: min(3.92vh, 32px);

                                    :last-of-type {
                                        margin-bottom: 0;
                                    }
                                `}
                                key={i}
                                onClick={() => scroll(data.link)}
                            >
                                {data.text} <ArrowIcon />
                            </CLink>
                        )
                    )}
                </Nav>
            </DialogContent>
        </Dialog>
    );
};
